import { useEffect } from "react";
import {
  ChakraProvider,
  // theme,
} from "@chakra-ui/react";
import theme from "./theme";
import { Routes, Route } from "react-router-dom";
import { HomePage } from "./HomePage";
import { LoginPage } from "./pages/LoginPage";
import { SignupPage } from "./pages/SignupPage";
import JudgementPage from "./pages/JudgementPage";
import { PerspectivePage } from "./pages/PerspectivePage";
import { SettlementPage } from "./pages/SettlementPage";
import { ProfilePage } from "./pages/ProfilePage";
import { CodifiedOptionsPage } from "./pages/CodifiedOptionsPage";
import { UaeLawJudgementPage } from "./pages/UaeLawJudgementPage";
import JudgementPagedev from "./pages/JudgementPagedev";
import { ForgotPasswordPage } from "./pages/ForgotPasswordPage";
import { TestCasesPage } from "./pages/TestCasesPage";
import DevelopmentPage from "./pages/DevelopmentPage";
import DisputeInvitationPage from "./pages/DisputeInvitationPage";
import DisputePage from "./pages/DisputePage";
import ProtectedRoute from "./auth/ProtectedRoute";
import { UserAuthContextProvider } from "./context/userAuthContext";
import ReactGA from 'react-ga4';
import DocumentationPage from "./pages/DocumentationPage";
import ArbiRulesPage from "./pages/ArbiRulesPage";
import ArbiClausePage from "./pages/ArbiClausePage";
import UseCasesPage from "./pages/UseCasesPage";
import SimpleJudgementPage from "./pages/SimpleJudgementPage";
import HowToUsePage from "./pages/HowToUsePage";
import HowToUseSimplePage from "./pages/HowToUseSimplePage";
import DevelopmentPage2 from "./pages/DevelopmentPage2";

const TRACKING_ID = "G-7D4SXVHE0X";

export const App = () => {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <UserAuthContextProvider>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route
            path="/codified_law"
            element={
              <ProtectedRoute>
                <CodifiedOptionsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/judgement"
            element={
              <ProtectedRoute>
                <JudgementPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/perspective"
            element={
              <ProtectedRoute>
                <PerspectivePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settlement"
            element={
              <ProtectedRoute>
                <SettlementPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <ProfilePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/uae_law"
            element={
              <ProtectedRoute>
                <UaeLawJudgementPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/judgement_demo"
            element={
              <JudgementPagedev />
            }
          />
          <Route
            path="/reset_password"
            element={
              <ForgotPasswordPage />
            }
          />
          <Route
            path="/test_cases"
            element={
              <TestCasesPage />
            }
          />
          <Route
            path="/dev_test"
            element={
              <ProtectedRoute>
                <DevelopmentPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dev_test2"
            element={
              <ProtectedRoute>
                <DevelopmentPage2 />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dispute_invite"
            element={
              <ProtectedRoute>
                <DisputeInvitationPage />
              </ProtectedRoute>
            }
          />
          <Route
            // path="/:invitee_:inviter"
            // path="/:invitee"
            path="/dispute/:invitee/:inviter/:invite_number" 
            element={
              <ProtectedRoute>
                <DisputePage />
              </ProtectedRoute>
            }
          />
          <Route
            // path="/:invitee_:inviter"
            // path="/:invitee"
            path="/docs" 
            element={
              <ProtectedRoute>
                <DocumentationPage />
              </ProtectedRoute>
            }
          />
          <Route
            // path="/:invitee_:inviter"
            // path="/:invitee"
            path="/arbitration_rules" 
            element={
              <ProtectedRoute>
                <ArbiRulesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/arbitration_clause" 
            element={
              <ProtectedRoute>
                <ArbiClausePage />
              </ProtectedRoute>
            }
          />
          {/* <Route
            path="/simplified_version" 
            element={
              <ProtectedRoute>
                <SimpleJudgementPage />
              </ProtectedRoute>
            }
          /> */}
          <Route
            path="/simplified_version" 
            element={
                <SimpleJudgementPage />
            }
          />
          <Route
            path="/use_cases" 
            element={
                <UseCasesPage />
            }
          />
          <Route
            path="/how_to_use"
            element={
                <HowToUsePage />
            }
          />
          <Route
            path="/how_to_use_simple"
            element={
                <HowToUseSimplePage />
            }
          />

          
        </Routes>
      </UserAuthContextProvider>
    </ChakraProvider>
  );
};